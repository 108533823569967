<template>
  <v-container primary fluid pa-0>
    <v-layout row wrap align-center max-width-1400 full-width ma-auto>
      <v-flex xs12 sm8 md7 lg6 pa-3>
        <p
          class="graphik-light white--text max-width-600"
          :class="{'font-36': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('about_benefits_title') }} <span class="graphik-bold">{{ $ml.get('about_benefits_title_benefits') }}</span></p>

        <p
          class="graphik-light font-16 white--text"
        ><v-icon color="white" class="font-18 mr-2">far fa-check-circle</v-icon> {{ $ml.get('content_benefits_step_2_description_1') }}</p>

        <p
          class="graphik-light font-16 white--text"
        ><v-icon color="white" class="font-18 mr-2">far fa-check-circle</v-icon> {{ $ml.get('content_benefits_step_2_description_2') }}</p>

        <p
          class="graphik-light font-16 white--text"
        ><v-icon color="white" class="font-18 mr-2">far fa-check-circle</v-icon> {{ $ml.get('content_benefits_step_2_description_3') }}</p>

        <p
          class="graphik-light font-16 white--text"
        ><v-icon color="white" class="font-18 mr-2">far fa-check-circle</v-icon> {{ $ml.get('content_benefits_step_2_description_4') }}</p>

        <p
          class="graphik-light font-16 white--text"
        ><v-icon color="white" class="font-18 mr-2">far fa-check-circle</v-icon> {{ $ml.get('content_benefits_step_2_description_5') }}</p>

        <p
          class="graphik-light font-16 white--text"
        ><v-icon color="white" class="font-18 mr-2">far fa-check-circle</v-icon> {{ $ml.get('content_benefits_step_2_description_6') }}</p>
      </v-flex>

      <v-flex xs12 sm4 md5 lg4 v-if="$vuetify.breakpoint.smAndUp">
        <v-img
          :aspect-ratio="1"
          contain
          class="ma-auto"
          position="center bottom"
          :src="image"
          :lazy-src="image"
        ></v-img>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ContentBenefitsPurple',
  data () {
    return {
      image: require('@/assets/img/images/benefits.png')
    }
  },
}
</script>
